// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---template-cache-tmp-barfoots-js": () => import("../.template-cache/tmp-barfoots.js" /* webpackChunkName: "component---template-cache-tmp-barfoots-js" */),
  "component---template-cache-tmp-tangmere-pepper-nurseries-js": () => import("../.template-cache/tmp-tangmere-pepper-nurseries.js" /* webpackChunkName: "component---template-cache-tmp-tangmere-pepper-nurseries-js" */),
  "component---template-cache-tmp-take-five-js": () => import("../.template-cache/tmp-take-five.js" /* webpackChunkName: "component---template-cache-tmp-take-five-js" */),
  "component---template-cache-tmp-contact-js": () => import("../.template-cache/tmp-contact.js" /* webpackChunkName: "component---template-cache-tmp-contact-js" */),
  "component---template-cache-tmp-case-studies-js": () => import("../.template-cache/tmp-case-studies.js" /* webpackChunkName: "component---template-cache-tmp-case-studies-js" */),
  "component---template-cache-tmp-what-i-do-js": () => import("../.template-cache/tmp-what-i-do.js" /* webpackChunkName: "component---template-cache-tmp-what-i-do-js" */),
  "component---template-cache-tmp-about-js": () => import("../.template-cache/tmp-about.js" /* webpackChunkName: "component---template-cache-tmp-about-js" */),
  "component---src-templates-post-blog-js": () => import("../src/templates/post/blog.js" /* webpackChunkName: "component---src-templates-post-blog-js" */),
  "component---template-cache-tmp-home-js": () => import("../.template-cache/tmp-home.js" /* webpackChunkName: "component---template-cache-tmp-home-js" */),
  "component---src-templates-post-index-js": () => import("../src/templates/post/index.js" /* webpackChunkName: "component---src-templates-post-index-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

